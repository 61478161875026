export const germanWords = [
  "wie",
  "ich",
  "seine",
  "dass",
  "er",
  "war",
  "fuer",
  "auf",
  "sind",
  "mit",
  "sie",
  "sein",
  "bei",
  "ein",
  "haben",
  "dies",
  "aus",
  "durch",
  "heiss",
  "Wort",
  "aber",
  "was",
  "einige",
  "ist",
  "es",
  "oder",
  "hatte",
  "die",
  "von",
  "zu",
  "und",
  "wir",
  "koennen",
  "andere",
  "waren",
  "tun",
  "ihre",
  "Zeit",
  "wenn",
  "werden",
  "sagte",
  "jeder",
  "sagen",
  "tut",
  "Satz",
  "drei",
  "wollen",
  "Luft",
  "gut",
  "auch",
  "spielen",
  "klein",
  "Ende",
  "setzen",
  "Zuhause",
  "lesen",
  "seits",
  "Hafen",
  "gross",
  "buchstabieren",
  "hinzufuegen",
  "Lande",
  "hier",
  "muss",
  "hoch",
  "so",
  "folgen",
  "Akt",
  "warum",
  "fragen",
  "Maenner",
  "Veraenderung",
  "ging",
  "Licht",
  "Art",
  "muessen",
  "Haus",
  "Bild",
  "versuchen",
  "uns",
  "wieder",
  "Tier",
  "Punkt",
  "Mutter",
  "Welt",
  "in der Naehe von",
  "bauen",
  "selbst",
  "Erde",
  "Vater",
  "neu",
  "Arbeit",
  "Teil",
  "nehmen",
  "erhalten",
  "Ort",
  "gemacht",
  "leben",
  "wo",
  "nach",
  "zurueck",
  "wenig",
  "nur",
  "Runde",
  "Mann",
  "Jahr",
  "kam",
  "zeigen",
  "mir",
  "geben",
  "unsere",
  "unter",
  "Name",
  "sehr",
  "Formular",
  "denken",
  "Hilfe",
  "niedrig",
  "Linie",
  "abweichen",
  "wiederum",
  "Ursache",
  "viel",
  "bedeuten",
  "vor",
  "Umzug",
  "Recht",
  "Junge",
  "alt",
  "gleich",
  "alle",
  "da",
  "nach oben",
  "Verwendung",
  "Weg",
  "ueber",
  "viele",
  "dann",
  "schreiben",
  "wuerde",
  "diese",
  "lange",
  "machen",
  "Sache",
  "sehen",
  "ihm",
  "zwei",
  "hat",
  "suchen",
  "mehr",
  "Tag",
  "koennte",
  "gehen",
  "kommen",
  "tat",
  "Anzahl",
  "klingen",
  "nicht",
  "am meisten",
  "Menschen",
  "meine",
  "wissen",
  "Wasser",
  "als",
  "Anruf",
  "erste",
  "nach unten",
  "Seite",
  "gewesen",
  "jetzt",
  "finden",
  "Kopf",
  "stehen",
  "besitzen",
  "sollte",
  "Land",
  "gefunden",
  "Antwort",
  "Schule",
  "wachsen",
  "Studie",
  "noch",
  "lernen",
  "Anlage",
  "Abdeckung",
  "Lebensmittel",
  "Sonne",
  "vier",
  "zwischen",
  "Zustand",
  "halten",
  "Auge",
  "nie",
  "letzte",
  "lassen",
  "Gedanken",
  "Stadt",
  "Baum",
  "ueberqueren",
  "Bauernhof",
  "schwer",
  "Beginn",
  "Macht",
  "Geschichte",
  "Saege",
  "weit",
  "Meer",
  "ziehen",
  "links",
  "spaet",
  "laufen",
  "unterlassen Sie",
  "waehrend",
  "Presse",
  "Schliessen",
  "Nacht",
  "realen",
  "wenige",
  "Norden",
  "Buch",
  "tragen",
  "nahm",
  "Wissenschaft",
  "essen",
  "Zimmer",
  "Freund",
  "begann",
  "Idee",
  "Fisch",
  "berg",
  "Stopp",
  "einmal",
  "Basis",
  "hoeren",
  "Pferd",
  "Schnitt",
  "sicher",
  "beobachten",
  "Farbe",
  "Gesicht",
  "Holz",
  "Haupt-",
  "geoeffnet",
  "scheinen",
  "zusammen",
  "naechste",
  "weiss",
  "Kinder",
  "Start",
  "bekam",
  "Beispiel",
  "erleichtern",
  "Papier",
  "Gruppe",
  "immer",
  "Musik",
  "diejenigen",
  "beide",
  "Marke",
  "oft",
  "bis",
  "Meile",
  "Fluss",
  "Auto",
  "Fuesse",
  "Pflege",
  "zweite",
  "genug",
  "Ebene",
  "Maedchen",
  "ueblich",
  "jung",
  "bereit",
  "oben",
  "je",
  "rot",
  "Liste",
  "obwohl",
  "fuehlen",
  "Vortrag",
  "Vogel",
  "bald",
  "Koerper",
  "Hund",
  "Familie",
  "direkt",
  "Pose",
  "verlassen",
  "Lied",
  "messen",
  "Tuer",
  "Produkt",
  "schwarz",
  "kurz",
  "Zahl",
  "Klasse",
  "Wind",
  "Frage",
  "passieren",
  "vollstaendig",
  "Schiff",
  "Bereich",
  "Haelfte",
  "Stein",
  "bestellen",
  "Feuer",
  "Sueden",
  "Problem",
  "Stueck",
  "wusste",
  "seit",
  "obere",
  "ganze",
  "Koenig",
  "Strasse",
  "Zoll",
  "multiplizieren",
  "nichts",
  "Kurs",
  "bleiben",
  "Rad",
  "voll",
  "Kraft",
  "blau",
  "Objekt",
  "entscheiden",
  "Oberflaeche",
  "tief",
  "Mond",
  "Insel",
  "Fuss",
  "System",
  "beschaeftigt",
  "Pruefung",
  "Rekord",
  "Boot",
  "gemeinsam",
  "goldenen",
  "moeglich",
  "Flugzeug",
  "statt",
  "trocken",
  "Wunder",
  "Lachen",
  "tausend",
  "lief",
  "ueberpruefen",
  "Spiel",
  "Form",
  "gleichsetzen",
  "Fehl",
  "gebracht",
  "Waerme",
  "Schnee",
  "Reifen",
  "bringen",
  "ja",
  "entfernt",
  "fuellen",
  "Osten",
  "malen",
  "Sprache",
  "Einheit",
  "fein",
  "fliegen",
  "fallen",
  "fuehren",
  "Schrei",
  "dunkel",
  "Maschine",
  "note",
  "warten",
  "Plan",
  "Abbildung",
  "Stern",
  "Kasten",
  "Nomen",
  "Feld",
  "Rest",
  "richtig",
  "faehig",
  "Pfund",
  "getan",
  "Schoenheit",
  "Antriebs",
  "stand",
  "enthalten",
  "Front",
  "lehren",
  "Woche",
  "Finale",
  "gab",
  "gruen",
  "oh",
  "schnell",
  "entwickeln",
  "Ozean",
  "warme",
  "kostenlos",
  "Minute",
  "stark",
  "besondere",
  "Geist",
  "hinter",
  "klar",
  "Schwanz",
  "produzieren",
  "Tatsache",
  "Raum",
  "gehoert",
  "beste",
  "Stunde",
  "besser",
  "wahr",
  "hundert",
  "fuenf",
  "merken",
  "Schritt",
  "frueh",
  "Westen",
  "Boden",
  "Interesse",
  "erreichen",
  "Verbum",
  "singen",
  "sechs",
  "Tabelle",
  "Reise",
  "weniger",
  "Morgen",
  "zehn",
  "einfach",
  "mehrere",
  "Vokal",
  "Krieg",
  "legen",
  "gegen",
  "Muster",
  "schleppend",
  "Zentrum",
  "Liebe",
  "Person",
  "Geld",
  "dienen",
  "erscheinen",
  "Karte",
  "regen",
  "Regel",
  "regieren",
  "Kaelte",
  "Hinweis",
  "Stimme",
  "Energie",
  "Jagd",
  "wahrscheinlich",
  "Bett",
  "Bruder",
  "Ei",
  "Fahrt",
  "Zelle",
  "glauben",
  "vielleicht",
  "pfluecken",
  "ploetzlich",
  "zaehlen",
  "Platz",
  "Grund",
  "Dauer",
  "vertreten",
  "Kunst",
  "Thema",
  "Region",
  "Groesse",
  "variieren",
  "regeln",
  "sprechen",
  "Gewicht",
  "allgemein",
  "Eis",
  "Materie",
  "Kreis",
  "Paar",
  "umfassen",
  "Kluft",
  "Silbe",
  "Filz",
  "Kugel",
  "Welle",
  "Herz",
  "Uhr",
  "vorhanden",
  "Tanz",
  "Motor",
  "Position",
  "Arm",
  "breit",
  "Segel",
  "Material",
  "Fraktion",
  "Wald",
  "sitzen",
  "Rennen",
  "Fenster",
  "Speicher",
  "Sommer",
  "Zug",
  "Schlaf",
  "beweisen",
  "einsam",
  "Bein",
  "Uebung",
  "Wand",
  "Fang",
  "wuenschen",
  "Himmel",
  "Board",
  "Freude",
  "Winter",
  "sa",
  "geschrieben",
  "wilden",
  "Instrument",
  "gehalten",
  "Glas",
  "Gras",
  "Kuh",
  "Rand",
  "Zeichen",
  "Besuch",
  "Vergangenheit",
  "weich",
  "Spass",
  "hell",
  "Gases",
  "Wetter",
  "Monat",
  "Million",
  "Finish",
  "gluecklich",
  "hoffen",
  "blume",
  "kleiden",
  "seltsam",
  "Vorbei",
  "Handel",
  "Melodie",
  "Buero",
  "empfangen",
  "Reihe",
  "Mund",
  "genau",
  "sterben",
  "am wenigsten",
  "Aerger",
  "ausser",
  "schrieb",
  "Samen",
  "Ton",
  "beitreten",
  "vorschlagen",
  "sauber",
  "Pause",
  "Dame",
  "Hof",
  "steigen",
  "schlecht",
  "Schlag",
  "Oel",
  "Blut",
  "beruehren",
  "wuchs",
  "Cent",
  "mischen",
  "Mannschaft",
  "Draht",
  "Kosten",
  "verloren",
  "braun",
  "Garten",
  "gesendet",
  "waehlen",
  "fiel",
  "passen",
  "fliessen",
  "Messe",
  "Bank",
  "sammeln",
  "sparen",
  "Kontrolle",
  "dezimal",
  "Ohr",
  "sonst",
  "ganz",
  "pleite",
  "Fall",
  "Mitte",
  "toeten",
  "Sohn",
  "See",
  "Moment",
  "Massstab",
  "laut",
  "Fruehling",
  "Kind",
  "gerade",
  "Konsonant",
  "Nation",
  "Woerterbuch",
  "milch",
  "Geschwindigkeit",
  "Verfahren",
  "Orgel",
  "zahlen",
  "Alter",
  "Abschnitt",
  "Kleid",
  "Wolke",
  "Ueberraschung",
  "ruhig",
  "winzig",
  "Aufstieg",
  "kuehlen",
  "Entwurf",
  "Menge",
  "Versuch",
  "Schluessel",
  "Eisen",
  "Einzel",
  "Stick",
  "Wohnung",
  "zwanzig",
  "Haut",
  "Laecheln",
  "Falte",
  "Loch",
  "springen",
  "acht",
  "Dorf",
  "treffen",
  "Wurzel",
  "kaufen",
  "erhoehen",
  "loesen",
  "Metall",
  "ob",
  "druecken",
  "sieben",
  "Absatz",
  "dritte",
  "wird",
  "Hand",
  "Haar",
  "beschreiben",
  "Koch",
  "entweder",
  "Ergebnis",
  "brennen",
  "Huegel",
  "Katze",
  "Jahrhundert",
  "betrachten",
  "Typ",
  "Gesetz",
  "Bit",
  "Kueste",
  "Kopie",
  "Ausdruck",
  "still",
  "Sand",
  "Rolle",
  "Temperatur",
  "Finger",
  "Industrie",
  "Wert",
  "Kampf",
  "Luege",
  "schlagen",
  "begeistern",
  "natuerlich",
  "Blick",
  "Sinn",
  "Hauptstadt",
  "wird nicht",
  "Stuhl",
  "Achtung",
  "Obst",
  "reich",
  "dick",
  "Soldat",
  "Prozess",
  "betreiben",
  "Praxis",
  "trennen",
  "schwierig",
  "Arzt",
  "Bitte",
  "schuetzen",
  "Mittag",
  "Ernte",
  "modernen",
  "Elementes",
  "Schueler",
  "Ecke",
  "Partei",
  "Versorgung",
  "deren",
  "lokalisieren",
  "Rings",
  "Charakter",
  "insekt",
  "gefangen",
  "Funk",
  "Speiche",
  "Atom",
  "Mensch",
  "Wirkung",
  "elektrisch",
  "erwarten",
  "Knochen",
  "Schiene",
  "vorstellen",
  "bieten",
  "zustimmen",
  "sanft",
  "Frau",
  "Kapitaen",
  "erraten",
  "erforderlich",
  "scharf",
  "Fluegel",
  "schaffen",
  "Nachbar",
  "Wasch",
  "Fledermaus",
  "eher",
  "mais",
  "vergleichen",
  "Gedicht",
  "Schnur",
  "Glocke",
  "abhaengen",
  "Fleisch",
  "einreiben",
  "Rohr",
  "beruehmt",
  "Dollar",
  "Strom",
  "Angst",
  "duenn",
  "Dreieck",
  "Eile",
  "Chef",
  "Kolonie",
  "Mine",
  "Krawatte",
  "eingeben",
  "Dur",
  "frisch",
  "Suche",
  "senden",
  "gelb",
  "Pistole",
  "erlauben",
  "Druck",
  "tot",
  "Stelle",
  "Wueste",
  "Anzug",
  "Aufzug",
  "stiegen",
  "ankommen",
  "Stamm",
  "Spur",
  "Elternteil",
  "Ufer",
  "Teilung",
  "Blatt",
  "Substanz",
  "beguenstigen",
  "verbinden",
  "verbringen",
  "Akkord",
  "Fett",
  "froh",
  "Original",
  "Aktie",
  "Station",
  "Papa",
  "Brot",
  "aufladen",
  "Leiste",
  "Angebot",
  "Segment",
  "Sklave",
  "ente",
  "Augenblick",
  "Markt",
  "Grad",
  "besiedeln",
  "kueken",
  "Feind",
  "antworten",
  "Getraenk",
  "auftreten",
  "Unterstuetzung",
  "Rede",
  "Natur",
  "Dampf",
  "Bewegung",
  "Fluessigkeit",
  "protokollieren",
  "gemeint",
  "Quotient",
  "Gebiss",
  "Schale",
  "Hals",
  "Sauerstoff",
  "Zucker",
  "Tod",
  "ziemlich",
  "Geschicklichkeit",
  "Frauen",
  "Saison",
  "Loesung",
  "Magnet",
  "Silber",
  "danken",
  "Zweig",
  "Suffix",
  "insbesondere",
  "Feige",
  "aengstlich",
  "riesig",
  "Schwester",
  "Stahl",
  "diskutieren",
  "vorwaerts",
  "aehnlich",
  "Erfahrung",
  "Partitur",
  "apfel",
  "gekauft",
  "gefuehrt",
  "Tonhoehe",
  "Mantel",
  "Masse",
  "Band",
  "Seil",
  "Rutsch",
  "gewinnen",
  "traeumen",
  "Abend",
  "Futtermittel",
  "Werkzeug",
  "gesamt",
  "Geruch",
  "Tal",
  "doppelt",
  "Sitz",
  "fortsetzen",
  "Block",
  "Hut",
  "verkaufen",
  "Erfolg",
  "Firma",
  "subtrahieren",
  "Veranstaltung",
  "schwimmen",
  "Begriff",
  "Gegenteil",
  "Schuh",
  "Schulter",
  "Verbreitung",
  "arrangieren",
  "Lager",
  "erfinden",
  "Baumwolle",
  "geboren",
  "bestimmen",
  "Quart",
  "neun",
  "Lastwagen",
  "Laerm",
  "Chance",
  "Geschaeft",
  "Stretch",
  "werfen",
  "Glanz",
  "Immobilien",
  "Spalte",
  "Molekuel",
  "falsch",
  "grau",
  "Wiederholung",
  "erfordern",
  "vorbereiten",
  "Salz",
  "Nase",
  "mehreren",
  "Zorn",
  "Anspruch",
  "Kontinent",
];
